import React from "react";
import Layout, { PageWrapper } from "../components/layout";
import { PageMain, PageSection } from "../styles/index/_components";

const Warsztaty = () => {
  return (
    <Layout>
      <PageMain>
        <PageSection>
          <PageWrapper>
            <div>Już wkrótce!</div>
          </PageWrapper>
        </PageSection>
      </PageMain>
    </Layout>
  );
};

export default Warsztaty;
